import React from "react";

export default function TwoPointO() {
  return (
    <>
     
      <div className="bg-black py-7">
        <h1 className=" text-white lg:text-7xl text-2xl md:text-5xl font-bold flex justify-center">
          RKM GOVERNMENT
        </h1>
      </div>
      <div className="carousel relative justify-start flex gap-1">
        <div className="carousel-item md:w-[50vw] w-full  justify-center relative">
          <img
            className="object-cover"
            src="https://weseedchange.org/wp-content/uploads/2019/10/Nepal-organic-farmer-Jit-Bahadur-Singar.jpg"
            alt="farmers"
          />
          <div className="absolute bottom-[20%] backdrop-blur-sm bg-slate/30 w-full py-1 md:py-2">
            <h2 className="text-5xl text-white text-center font-semibold">
              FARMERS
            </h2>
          </div>
          <div className="absolute bottom-[10%]">
            <h2 className="text-white border text-center backdrop-blur-sm bg-slate/30 w-full py-1 px-4 cursor-pointer">
              Read More
            </h2>
          </div>
        </div>
        <div className="carousel-item md:w-[50vw] w-full justify-center relative">
          <img
            className="object-cover"
            src="https://images.squarespace-cdn.com/content/v1/553fd22be4b09bfe06cec52e/1477089828042-WZLFZ4UX0B16V7FDQQ7N/Nepal+volunteer+education+womens+education+THISWORLDEXISTS?format=1500w"
            alt="Women"
          />
          <div className="absolute bottom-[20%] backdrop-blur-sm bg-slate/30 w-full py-1 md:py-2">
            <h2 className="text-5xl text-white text-center font-semibold">
              WOMENS
            </h2>
          </div>
          <div className="absolute bottom-[10%]">
            <h2 className="text-white border text-center backdrop-blur-sm bg-slate/30 w-full py-1 px-4 cursor-pointer">
              Read More
            </h2>
          </div>
        </div>
        <div className="carousel-item md:w-[50vw] w-full justify-center relative">
          <img
            className="object-cover"
            src="https://www.inf.org.uk/wp-content/uploads/2019/06/kids.jpg"
            alt="Poor"
          />
          <div className="absolute bottom-[20%] backdrop-blur-sm bg-slate/30 w-full py-1 md:py-2">
            <h2 className="text-5xl text-white text-center font-semibold">
              POOR
            </h2>
          </div>
          <div className="absolute bottom-[10%]">
            <h2 className="text-white border text-center backdrop-blur-sm bg-slate/30 w-full py-1 px-4 cursor-pointer">
              Read More
            </h2>
          </div>
        </div>
        <div className="carousel-item md:w-[50vw] w-full justify-center relative">
          <img
            className="object-cover"
            src="https://www.fiscalnepal.com/wp-content/uploads/2021/10/Infrastructure-1.jpg"
            alt="infrastructures"
          />
          <div className="absolute bottom-[20%] backdrop-blur-sm bg-slate/30 w-full py-1 md:py-2">
            <h2 className="text-5xl text-white text-center font-semibold">
              INFRASTRUCTURES
            </h2>
          </div>
          <div className="absolute bottom-[10%]">
            <h2 className="text-white border text-center backdrop-blur-sm bg-slate/30 w-full py-1 px-4 cursor-pointer">
              Read More
            </h2>
          </div>
        </div>
        <div className="carousel-item md:w-[50vw] w-full justify-center relative">
          <img
            className="object-cover"
            src="https://english.makalukhabar.com/wp-content/uploads/2022/06/Checking-Pressure-MK.jpg"
            alt="health"
          />
          <div className="absolute bottom-[20%] backdrop-blur-sm bg-slate/30 w-full py-1 md:py-2">
            <h2 className="text-5xl text-white text-center font-semibold">
              HEALTH
            </h2>
          </div>
          <div className="absolute bottom-[10%]">
            <h2 className="text-white border text-center backdrop-blur-sm bg-slate/30 w-full py-1 px-4 cursor-pointer">
              Read More
            </h2>
          </div>
        </div>
        <div className="carousel-item md:w-[50vw] w-full justify-center relative">
          <img
            className="object-cover"
            src="https://www.vaticannews.va/content/dam/vaticannews/multimedia/2024/agosto/16/03.jpg/_jcr_content/renditions/cq5dam.thumbnail.cropped.750.422.jpeg"
            alt="youth"
          />
          <div className="absolute bottom-[20%] backdrop-blur-sm bg-slate/30 w-full py-1 md:py-2">
            <h2 className="text-5xl text-white text-center font-semibold">
              YOUTH
            </h2>
          </div>
          <div className="absolute bottom-[10%]">
            <h2 className="text-white border text-center backdrop-blur-sm bg-slate/30 w-full py-1 px-4 cursor-pointer">
              Read More
            </h2>
          </div>
        </div>
        <div className="carousel-item md:w-[50vw] w-full justify-center relative">
          <img
            className="object-cover"
            src="https://images.news18.com/ibnlive/uploads/2023/05/nepal-economy-16845753343x2.jpg?impolicy=website&width=640&height=480"
            alt="economy"
          />
          <div className="absolute bottom-[20%] backdrop-blur-sm bg-slate/30 w-full py-1 md:py-2">
            <h2 className="text-5xl text-white text-center font-semibold">
              ECONOMY
            </h2>
          </div>
          <div className="absolute bottom-[10%]">
            <h2 className="text-white border text-center backdrop-blur-sm bg-slate/30 w-full py-1 px-4 cursor-pointer">
              Read More
            </h2>
          </div>
        </div>
        <div className="carousel-item md:w-[50vw] w-full justify-center relative">
          <img
            className="object-cover"
            src="https://www.planetware.com/photos-large/NEP/nepal-kathmandu-city-view.jpg"
            alt="DEVELOPMENT"
          />
          <div className="absolute bottom-[20%] backdrop-blur-sm bg-slate/30 w-full py-1 md:py-2">
            <h2 className="text-5xl text-white text-center font-semibold">
              DEVELOPMENT
            </h2>
          </div>
          <div className="absolute bottom-[10%]">
            <h2 className="text-white border text-center backdrop-blur-sm bg-slate/30 w-full py-1 px-4 cursor-pointer">
              Read More
            </h2>
          </div>
        </div>
        <div className="carousel-item md:w-[50vw] w-full justify-center relative">
          <img
            className="object-cover"
            src="https://assets.thehansindia.com/h-upload/2020/11/05/1009820-india-vs-nepal-defence.webp"
            alt="defence"
          />
          <div className="absolute bottom-[20%] backdrop-blur-sm bg-slate/30 w-full py-1 md:py-2">
            <h2 className="text-5xl text-white text-center font-semibold">
              DEFENCE
            </h2>
          </div>
          <div className="absolute bottom-[10%]">
            <h2 className="text-white border text-center backdrop-blur-sm bg-slate/30 w-full py-1 px-4 cursor-pointer">
              Read More
            </h2>
          </div>
        </div>
      </div>
    </>
  );
}
