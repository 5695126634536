import axios from "axios";
import React from "react";
import { useUserStore } from "../hooks/useUserStore";

export default function SingleImage(props) {
  console.log({props});
  const user = useUserStore((state) => state.user);
  const handleDelete = async () => {
    const isConfirmed = window.confirm('Are you sure you want to delete this item?');
  
    if (isConfirmed) {
      try {
        const result = await axios.delete(`http://localhost:8080/api/photo/delete/${props?.id}`);
        console.log(result);
        window.location.reload();
       
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log('Deletion canceled');
    }
  };
  
  return (
    <div>
      <img
        className="hover:-translate-y-2 hover:shadow-xl duration-500 h-auto max-w-full rounded-lg"
        src={props.pic}
        alt="image"
      />
        {user?.usertype === "admin" ? (  <button onClick={handleDelete}>Delete</button>):("")}
    
    </div>
  );
}
