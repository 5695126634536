import "./App.css";
import Videos from "./components/VideoGallery.jsx";
import Photos from "./components/ImageGallery.jsx";
import Navbar from "./components/Navbar";
import LandingPage from "./components/LandingPage.jsx";
import Contact from "./components/Contact.jsx";
import All_Join from "./components/All_join.jsx";
import Join2 from "./components/Join2.jsx";
import Join3 from "./components/Join3.jsx";
import Login from "./components/Login.jsx";
import About from "./components/About.jsx";
import Footer from "./components/Footer.jsx";
import Articles from "./components/Articles.jsx";



// small component 
import AddLeader from "./smallComponent/AddLeader.jsx";
import { Routes, BrowserRouter, Route } from "react-router-dom";
import { useUserStore } from "./hooks/useUserStore.js";
import { useEffect } from "react";
import axios from "axios";
import Leader from "./components/Leader.jsx";
import UploadPhoto from "./smallComponent/UploadPhoto.jsx";
// import { Footer } from "flowbite-react";

const App = () => {
  const setLogin = useUserStore((state) => state.login);
  // const setIsFetching = useUserStore(state => state.setIsFetching);
  const isAuthenticated = useUserStore((state) => state.isAuthenticated);
  async function fetchUser() {
    // console.log(setLogin)

    try {
      console.log(localStorage.getItem("token"));

      const user = await axios.get("http://localhost:8080/api/user/profile", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      console.log(user.data.user, "1234");
      setLogin(user.data.user, localStorage.getItem("token"));
    } catch (err) {
      console.error({ err: err.message });
    } finally {
      // setIsFetching(false);
    }
  }
  useEffect(() => {
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className=" App text">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/join" element={<All_Join />} />
          <Route path="/join2" element={<Join2 />} />
          <Route path="/join3" element={<Join3 />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="/photos" element={<Photos />} />
          <Route path="/videos" element={<Videos />} />
          <Route path="/leader" element={<Leader />} />
          <Route path="/about" element={<About />} />
          <Route path="/addleader" element={<AddLeader />} />
          <Route path="/article" element={<Articles />} />
          <Route path="/upload" element={<UploadPhoto />} />
        </Routes>
        <Footer/>
      </BrowserRouter>
    </div>
  );
};

export default App;
