import React from "react";
import thumbnail from "../assets/imagesgallery/img15.jpeg";
import { Link } from "react-router-dom";

export default function () {
  return (
    <div className="bg-black rounded-md">
      <div className="flex justify-center p-6 md:px-7 w-full items-center">
        {/* Articles frame  */}
        <iframe
          src="https://www.nepalviews.com/2024/09/17/314376/"
          height="700"
          className="border-1 md:px-7 border-black w-full sm:w-[70%]  md:w-[50%]"
        ></iframe>
        {/* Videos and photos */}

        <div className="md:flex flex-col hidden  gap-8">
          <div class="max-w-sm h-full w-full">
            <iframe
              width="400"
              height="300"
              src="https://www.youtube.com/embed/27NUB19cUus?si=5Xq4fCIdXMi04fqN"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
          <div>
            <Link to="/photos">
              <img
                class=" h-[250px] w-[400px] hover:-translate-y-2 hover:shadow-xl duration-500  max-w-full rounded-lg"
                src={thumbnail}
                alt="image"
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="md:hidden flex bg-black flex-col justify-center items-center gap-8">
        <div class="max-w-sm ">
          <iframe
          className="min-h-[55vw] sm:min-h-[60vw] sm:min-w-[70vw] min-w-[70vw]"
            src="https://www.youtube.com/embed/27NUB19cUus?si=5Xq4fCIdXMi04fqN"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <Link to="/photos">
            <img
              class=" h-[250px] w-[400px] hover:-translate-y-2 hover:shadow-xl duration-500  max-w-full rounded-lg"
              src={thumbnail}
              alt="image"
            />
          </Link>
        </div>
      </div>
    </div>
  );
}
